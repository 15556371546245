.about__feature {
  .wpb_column > .vc_column-inner > .wpb_wrapper {
    display: flex;
    padding: 2em 1em;
    margin-top: 4em;
    background-color: var(--color__brown);
    flex-flow: row wrap;
    justify-content: center;
    @media (--phone){
      margin-top: 15px;
    }
  }

  .wpb_single_image {
    display: flex;
    width: calc(100% / 5);
    flex-flow: column nowrap;
    align-items: center;
    @media (--ipad){
      width: calc(100% / 3);
      margin-top: 2em;
    }
    @media (--ipad_mini){
      width: calc(100% / 3);
      margin-top: 2em;
    }
    @media (--phone){
      width: calc(100% / 2);
      margin-top: 2em;
    }
  }

  .wpb_heading {
    order: 2;
    margin-top: 1em;
    font: .9rem/1.2 regular, serif;
    color: white;
    @media (--ipad){
      max-width: 10em;
    }
    @media (--ipad_mini){
      max-width: 10em;
    }
    @media (--phone){
      max-width: 10em;
      font-size: 1rem;
    }
  }
}
