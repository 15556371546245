.contact-enquiry {
  .wpcf7 {
    input {
      @mixin formInput;

      &.wpcf7-text {
        width: calc((100% -8px) / 3);
        @media (--phone){
          width: 100%;
        }
      }

      &[type="email"],
      &[type="tel"] {
        margin-left: 4px;
        @media (--phone){
          margin-left: 0;
          margin-top: 4px;
        }
      }

      &[type="submit"] {
        display: block;
        margin: 1em auto 0;
        padding: .4em 2.4em;
        border-radius: 0;
        background-color: color(blue b(+50%));
        border-color: color(blue b(+50%));
        text-transform: capitalize;
      }
    }

    textarea {
      @mixin formInput;
      margin-top: 4px;
      resize: none;
    }
  }
}
