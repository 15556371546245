body,
html {
  position: relative;
  margin-top: 0 !important;
  padding: 0 !important;
  overflow-x: hidden;
  line-height: 1.4;
  font-size: responsive 14px 20px;
  font-range: 420px 1280px;
}

h1,h2,h3,
h4, h5, h6,
p, ul {
  margin: 0;
}

ul {
  padding-left: 0;
}

.page .entry-header,
.entry-footer {
  display: none !important;
}

.content-area .hentry,
.page-wrap {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.admin-bar .site-header.float-header,
.admin-bar .site-header {
  top: 0;
}

.page-wrap .content-wrapper {
  padding-top: 0;
  padding-bottom: 0;
  background-color: transparent;
}

.page .page-wrap .content-wrapper, 
.single .page-wrap .content-wrapper {
  padding: 0;
}

.wpb_button, .wpb_content_element {
  margin-bottom: 0 !important;
}

.vc_row-no-padding .vc_inner {
  margin-left: auto !important;
  margin-right: auto !important;
}

.wpb_gmaps_widget .wpb_wrapper {
  padding: 0 !important;
}

.vc_section {
  margin-left: 0;
  margin-right: 0;
}

.container {
  margin-left: auto !important;
  margin-right: auto !important;
}

.wpb_column {
  > .vc_column-inner {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (max-width: 991px){
  .header-wrap .col-md-8 {
    padding-left: 15px;
    padding-right: 15px;
  }
}
