#mainnav {
  float: none;

  .menu-item {
    padding: 0 1.5em;

    &:last-child {
      padding-right: 0;
    }

    &:hover {
      .sub-menu {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    > a {
      text-transform: capitalize;
      font-family: regular, serif;
    }
  }

  .menu {
    display: flex;
    justify-content: flex-end;
  }

  .sub-menu {
    --w: 260px;

    left: 50%;
    right: auto;
    width: var(--w);
    transform: translateX(-40%);

    &::after {
      visibility: hidden;
    }

    .menu-item {
      padding: 0;

      > a {
        width: var(--w);
        padding: .5em 1.4em !important;
        border-top: none !important;
        font-size: .7em;
        line-height: 1.2;
      }
    }
  }
}
