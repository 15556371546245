.social {
    display: inline-block;
    width: 1em;
    height: 1em;
    border-radius: .2em;
    background-color: var(--color__brown);
    text-align: center;
    font-size: 1rem;
    line-height: .8;
    @media (--phone){
        font-size: 1.4rem;
    }

    &:before {
        font-size: .8em;
        line-height: .6;
    }

    + & {
        margin-left: 10px;
    }
}
