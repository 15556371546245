.widget_products {
    padding-left: 1em;
    @media (--ipad){
      padding-top: 30px !important;
      padding-left: 0 !important;
    }
    @media (--ipad_mini){
      padding-top: 30px !important;
      padding-left: 0 !important;
    }
    @media (--phone){
      padding-top: 30px !important;
      padding-left: 0 !important;
    }

    .widget-title {
        padding-bottom: .5em !important;
        font: 1.2rem/1.2 bold, serif;
        text-align: center;
        @media (--desktop){
          text-align: left;
        }
    }

    .attachment-woocommerce_thumbnail {
        display: none;
    }

    .product-title {
      display: block;
      font: .9rem/1.2 regular, serif;
      @media (--phone){
        font-size: 1.1rem;
      }
    }

    .product_list_widget {
      display: flex;
      flex-flow: row wrap;
      @media (--desktop){
        display: block;
        flex-flow: none;
      }
    }

    li {
      width: 100%;
      text-align: left;
      @media (--desktop){
        border-bottom: none !important;
      }
      &:first-child {
        padding-top: 5px !important;
      }
    }
}
