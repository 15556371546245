.about {
  position: relative;
  @media (--phone){
    padding-bottom: 15px !important;
  }

  &&_bg {
    background: url(../images/about_bg@1x.png) right bottom/50% auto no-repeat;
  }
}
