#primary {
    order: 2;
    @media (--ipad){
        width: 100%;
    }
    @media (--ipad_mini){
        width: 100%;
    }
    @media (--phone){
        width: 100%;
    }
}
