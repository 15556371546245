.woocommerce {
  .products {
    .product {
      margin-bottom: 1.7em !important;
    }

    .woocommerce-LoopProduct-link {
      display: block;
      overflow: hidden;
      position: relative;

      &:before,
      &:after {
        opacity: 0;
        transition: opacity .4s linear;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        padding-top: 100%;
        background-color: color( var(--color__brown) a(.7));
      }

      &:after {
        content: 'view more';
        position: absolute;
        top: 36%;
        left: 0;
        right: 0;
        text-align: center;
        text-transform: uppercase;
        font: 1rem/1.2 bold, serif;
        color: white;
        @media (--phone){
          font-size: 1.2rem;
        }
      }

      &:hover {
        &:before,
        &:after {
          opacity: 1;
        }
      }
    }

    .attachment-woocommerce_thumbnail {
      margin-bottom: 0 !important;
    }

    .woocommerce-loop-product__title {
      padding: .5em 0 !important;
      font: 1.2rem/1.2 regular, serif !important;
      color: var(--color__text);
    }

    .button {
      display: none;
    }
  }

  /* COMMONS */
  &.woocommerce-page {
    .site-main {
      padding-top: 3em;
      padding-left: 1em;
      padding-right: 1em;
    }

    .woocommerce-breadcrumb {
      margin-bottom: 0;
      text-align: center;
      border-bottom: none;
      text-transform: uppercase;
      color: var(--color__brown);

      > a {
        color: inherit;
      }
    }

    .woocommerce-products-header {
      margin-bottom: 2em;
      text-align: center;

      > .archive-title {
        font: 1.6rem/1.2 bold, serif;
        color: var(--color__brown);
        text-transform: uppercase;
      }
    }

    .woocommerce-ordering,
    .woocommerce-result-count {
      display: none;
    }
  }

  /* SINGLE PRODUCT */
  &&.single-product {
    @media (--phone){
      .content-wrapper {
        display: flex;
        flex-flow: column nowrap;

        #secondary {
          order: -1;
          margin-top: 15px;
          margin-bottom: 0;
        }
      }

      .site-main {
        padding: 1em 0 0;
      }
    }

    .summary.entry-summary {
      display: none;
    }

    .product_meta,
    .woocommerce-product-gallery,
    .woocommerce-Tabs-panel > h2,
    .tabs.wc-tabs,
    .related.products,
    .price {
      display: none;
    }

    .product_title.entry-title {
      font: 1.6rem/1.2 bold, serif;
      text-transform: uppercase;
      text-align: center;
      color: var(--color__brown);
      @media (--phone){
        margin-bottom: 0;
      }
    }

    .woocommerce-product-details__short-description {
      font: .8rem/1.4 regular, serif;
      @media (--phone){
        font-size: 1rem;
      }
    }
    
    .wpb_single_image .wpb_heading {
      margin-top: .5em !important;
      font-weight: 700 !important;
      font-size: 1.2rem !important;

      small {
        font-size: .85em !important;
      }
    }

    .wpb_single_image.is-bigger {
      .vc_single_image-img {
        max-width: 100%;
        width: 500px;
      }
    }

    @media (--phone){
      .wpb_column > .vc_column-inner {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }

      .vc_section > .vc_row {
        > .wpb_column {
          &.vc_col-sm-3,
          &.vc_col-sm-4 {
            width: calc(50% - 7.5px);
            margin-top: 3em;

            &:nth-child(odd){
              float: left;
            }
            &:nth-child(even){
              float: right;
            }
          }

          &.vc_col-sm-8 + .vc_col-sm-4 {
            width: 100%;
            float: none;
          }
        }
      }
    }
  }
}
@each $i in 401, 393, 389, 292, 287, 283, 258, 239, 70, 60, 59, 57, 56, 52, 55, 54, 53 {
  .woocommerce.postid-$(i) {
    #secondary {
      overflow: hidden;
    }

    .product_list_widget {
      .pro-$(i) {
        position: relative;
        z-index: var(--zIndex__bottom);

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          height: 100%;
          background-color: white;
          @media (--desktop){
            width: 200%;
          }
        }

        > a {
          position: relative;
          padding-left: 1em;
          padding-right: 1em;
          z-index: var(--zIndex__bottom);
          color: var(--color__text) !important;
          @media (--desktop) {
            padding-left: 0;
          }
        }
      }
    }
  }
}
