:root {
    --color__primary: #b1874a;
    --color__brown: #b2963f;
    --color__text: #333;

    --zIndex__top: 9999;
    --zIndex__middle: 999;
    --zIndex__bottom: 99;
}
@define-mixin tagline {
    height: 2.2em;
    background: url(../images/company_name@1x.png) center/contain no-repeat;
}

@define-mixin formInput {
    background-color: transparent;
    border-color: color(white a(.4));
    font: 16px/1.2 regular, serif;
    color: white;

    &:focus {
        outline: 0;
    }

    &::placeholder {
        opacity: 1;
        color: white;
    }
}
