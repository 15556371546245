.footer-widgets {
  overflow: hidden;
  padding: 1em;
  background: url(../images/ft_widget_bg_1.png) 0 0/50% auto no-repeat,
  url(../images/ft_widget_bg_2.png) 100% 100%/50% auto no-repeat,
  linear-gradient(180deg, color(#b5943c a(.5)) 47%, var(--color__brown) 0, var(--color__brown));
  @media (--desktop) {
    padding: 1em 0;
    background: url(../images/ft_widget_bg_1.png) 0 0/contain no-repeat,
    url(../images/ft_widget_bg_2.png) 100% 100%/contain no-repeat,
    linear-gradient(90deg, var(--color__brown), var(--color__brown) calc(100% / 12 * 3), color(#b5943c a(.5)) 0, color(#b5943c a(.5)) calc(100% / 12 * 7.2), var(--color__brown) 0, var(--color__brown) 100%);
  }

  &&_full {
    background: linear-gradient(180deg, color(#b5943c a(.5)) 47%, var(--color__brown) 0, var(--color__brown));
    @media (--desktop) {
      padding: 1em 0;
      background: linear-gradient(90deg, var(--color__brown), var(--color__brown) calc(( 100% - 1170px ) / 2), color(#b5943c a(.5)) 0, color(#b5943c a(.5)) 44.5%, var(--color__brown) 0, var(--color__brown) 100%);
    }

    .wpcf7 {
      padding-right: 0;
    }
  }

  .widget {
    padding-top: 0;
    margin-bottom: .7em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .widget-title {
    padding-bottom: 0;
    margin-top: 0;
    font: 1rem/1.2 sbold, serif;
    color: var(--color__brown);
    text-transform: uppercase;
  }

  #media_image-2 {
    text-align: center;
  }

  address {
    display: flex;
    overflow: hidden;
    margin-bottom: 0;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  dl {
    width: calc(50% - .5em);
    margin-bottom: 10px;
    font: .9rem/1.2 regular, serif;
    vertical-align: top;

    + & {
      margin-left: 1em;
    }

    dt {
      font-weight: normal;
      color: var(--color__brown);
      text-transform: capitalize;
    }

    dd {
      color: var(--color__text);
    }
  }

  #custom_html-5 {
    margin-bottom: 10px;
  }

  #nav_menu-2 {
    .widget-title {
      font: .9rem/1.2 regular, serif;
    }
  }

  .menu {
    &-item {
      display: inline-block;
      padding: 0 10px !important;
      text-transform: capitalize;
      font: .9rem/.8 regular, serif;

      + .menu-item {
        border-left: 1px solid var(--color__text);
      }

      &:first-child {
        padding-left: 0 !important;
      }

      &:last-child {
        padding-right: 0 !important;
      }

      > a {
        color: var(--color__text) !important;
      }
    }

    .sub-menu {
      display: none;
    }
  }

  #custom_html-3 {
    padding-top: 2em;
    text-transform: uppercase;
    @media (--phone){
      padding-top: 5em;
    }
  }

  .wpcf7 {
    padding-right: 1.4em;
    margin-top: 1.4em;
    @media (--ipad){
      padding-right: 0;
    }
    @media (--ipad_mini){
      padding-right: 0;
    }
    @media (--phone){
      padding-right: 0;
    }

    input {
      &[type="text"],
      &[type="email"],
      &[type="tel"] {
        @mixin formInput;
        width: 100%;
        height: 2.5em;
        padding-left: .5em;
        margin-top: 10px;
      }

      &[type="submit"] {
        display: block;
        margin-top: 1em;
        margin-left: auto;
        padding: .7em 3em;
        border: none;
        border-radius: 0;
        background-color: white;
        text-transform: capitalize;
        color: var(--color__brown);

        &:hover {
          background-color: transparent;
          color: white;
        }
      }
    }

    textarea {
      margin-top: 10px;
      height: 5em;
      padding-left: .5em;
      @mixin formInput;
      resize: none;
    }
  }

  @media (--phone){
    .sidebar-column {
      padding-left: 1em;
      padding-right: 1em;
    }
  }
}
