.contact-informations {
  & &__item {
    position: relative;
    padding: .2em 0 .2em 1.5em;
    @media (--phone) {
      text-align: center;
    }

    &::before {
      content: '';
      position: absolute;
      top: .3em;
      left: 0;
      width: 1em;
      height: 1em;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      @media (--phone) {
        display: inline-block;
        position: static;
        margin-right: .4em;
        transform: translateY(20%);
      }
    }
  }
}
@each $i in address, tel, fax, email {
  .contact-informations .contact-informations__item-$(i)::before {
    @if $i == address {
      height: 1.3em;
    }
    background-image: url(../images/icon_$(i)@1x.png);
  }
}
