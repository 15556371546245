.widget_product_search {
    margin-bottom: 0 !important;

    .woocommerce-product-search {
        position: relative;
        margin: 0 auto;
        @media (--ipad){
            max-width: 24em;
        }
        @media (--ipad_mini){
            max-width: 20em;
        }
        @media (--phone){
            max-width: 15em;
        }
    }

    .search-field {
        height: 1.8em;
        background-color: white;
        border: none;
        border-radius: 1em;
        font: 1rem/1.2 sbold, serif;
        color: var(--color__brown);
        @media (--phone){
          height: 2.4em;
          border-radius: 2em;
        }

        &:focus {
            border: none;
            outline: 0;
        }

        &::placeholder {
            opacity: 1;
            color: var(--color__brown);
        }
    }

    button[type="submit"] {
        position: absolute;
        top: 50%;
        right: 10px;
        width: 20px;
        height: 20px;
        padding: 0;
        border-radius: 0;
        border: none;
        background: url(../images/icon_search.png) center/contain no-repeat;
        font-size: 0;
        transform: matrix(1, 0, 0, 1, 0, -10);
    }
}
