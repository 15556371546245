.site-header {
  @media (--phone){
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &.float-header {
    #mainnav:after {
      display: none;
    }
  }

  .header__info {
    list-style: none;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid black;
    font-size: 11px;
    @media (--desktop) {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }

    i {
      display: inline-block;
      margin-right: 5px;
      font-style: italic;
    }

    .header__info-item + .header__info-item {
      @media (--phone) {
        padding-top: 10px;
      }
    }
  }
}
