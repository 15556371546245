.pum {
  div.wpcf7 {
    input,
    textarea {
      border-color: var(--color__text);
      color: var(--color__text);

      &::placeholder {
        color: var(--color__text);
      }
    }
  }
}
