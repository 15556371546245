.wpb_single_image {
  display: flex;
  flex-flow: column nowrap;

  .wpb_heading {
    order: 2;
    margin-top: 1em !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    color: var(--color__brown);
    font: 1rem/1.2 regular, serif !important;

    > small {
      color: var(--color__brown);
    }
  }
}
