a.floating-button {
  position: fixed;
  width: 50px;
  height: 50px;
  line-height: 50px;
  right: 30px;
  font-size: 20px;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  z-index: 1000;
  animation: pulsess 3s infinite;

  &:hover {
    background-color: rgba(220, 16, 16, .45);
    color: #fff;
    animation-play-state: paused;
  }

  &_green {
    background-color: #25D366;
  }

  &_blue {
    background-color: #01144f;
  }

  &_red {
    background-color: #c12d2d;
  }
}

@for $i from 1 to 3 {
  a.floating-button_$(i) {
    --i: $i;
    bottom: calc(var(--i) * 72px);
  }
}

@keyframes pulsess {
  0% {
    box-shadow: 0 0 0 0 rgba(0,0,0, .3);
  }
  30% {
    box-shadow: 0 0 0 10px rgba(0,0,0, 0.1);
  }
  50% {
    box-shadow: 0 0 0 0 rgba(0,0,0, 0);
  }
  80% {
    box-shadow: 0 0 0 10px rgba(0,0,0, 0.1);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0,0,0, 0);
  }
}
