.content-wrapper {
  overflow: hidden;
  display: flex;
  flex-flow: row wrap;

  .post-wrap {
    padding-right: 1em;
    padding-left: 1em;
    @media (--desktop){
      padding-right: 2em;
      padding-left: 2em;
    }
    @media (--phone){
      padding-right: 0;
      padding-left: 0;
    }
  }
}
