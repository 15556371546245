.about__main {
  padding: 1em 2em 0;

  &:before {
    content: '';
    position: absolute;
    top: -3em;
    left: -1em;
    width: 2em;
    height: 4.7em;
    border-left: 2px solid var(--color__primary);
    border-bottom: 2px solid var(--color__primary);
  }
}
