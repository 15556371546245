.tagline {
  position: absolute;
  top: 5px;
  left: 20px;
  width: calc(100% - 120px);
  @mixin tagline;
  background-position-x: left;
  @media (--desktop) {
    top: 50%;
    height: 1.5em;
    transform: translateY(-50%);
  }
}
