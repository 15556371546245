#secondary {
  padding: 2em 1em 0;
  @media (--ipad){
    order: 3;
    width: 100%;
  }
  @media (--ipad_mini){
    order: 3;
    width: 100%;
  }
  @media (--phone){
    order: 3;
    width: calc(100% - 30px);
    float: none;
    margin: 0 auto 15px;
  }
}
